import React from 'react';
import Layout from '../../components/common/Layout.jsx';
import HeartDiseaseLayout from '../../components/heart-disease/HeartDiseaseLayout.jsx';

import { connect } from 'react-redux';
import { HEART_DISEASE_NAVIGATION } from '../../state/actionTypes.js';

class Diagnosis extends React.PureComponent {
    constructor(props){
        super(props);
        props.heartDiseaseNavigation(3);
    }

    render(){
        return (
            <Layout>
                <HeartDiseaseLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">It's important that you and your dog pay regular visits to the vet. Discuss with your vet if you see any signs of heart failure in your dog, such as:</p>
                                <ul>
                                    <li className="SectionListElement">&ndash; Laboured or fast breathing at rest</li>
                                    <li className="SectionListElement">&ndash; Reluctance to exercise and tiring more easily</li>
                                    <li className="SectionListElement">&ndash; Poor appetite</li>
                                    <li className="SectionListElement">&ndash; Weight loss</li>
                                    <li className="SectionListElement">&ndash; Enlarged abdomen</li>
                                    <li className="SectionListElement">&ndash; Weakness</li>
                                    <li className="SectionListElement">&ndash; Fainting &mdash; often associated with exercise</li>
                                </ul>
                                <p className="SectionText">Your vet will pick up clues to any heart-related problems with a thorough physical examination of your dog. By listening to your dog's heart with a stethoscope, your vet can assess heart rate and rhythm, and detect a murmur if one is present.</p>
                                <p className="SectionText">This gives your vet the greatest possible chance for early detection and treatment of heart disease. It also allows the vet to monitor your dog's treatment on an ongoing basis.</p>
                                <p className="SectionText">Your vet might also recommend radiographs (X-rays) or an ultrasound for your dog. These tests can help them detect fluid on the lungs, or an increase in heart size.</p>
                                <p className="SectionText">Another test your vet may use is an electrocardiogram (ECG) to record the electrical activity of the heart. This is a useful tool, particularly if there are problems with the rhythm of your dog's heartbeat.</p>
                            </div>
                        </div>
                    </div>
                </HeartDiseaseLayout>
            </Layout>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        heartDiseaseNavigation: heartDiseasePage => dispatch({
            type: HEART_DISEASE_NAVIGATION,
            heartDiseasePage,
        })
    }
}

export default connect(null, mapDispatchToProps)(Diagnosis);
